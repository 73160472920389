import React from 'react';
import { message} from 'antd';

import qrcode from '../../images/baijiaqm.jpg';

import './aboutMe.css';

class AboutMe extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showmodel:false
        }
    }

    handleCopyWechat = () => {
        const spanText = document.getElementById('follow_wechat').innerText;
        const oInput = document.createElement('input');
        oInput.value = spanText;
        document.body.appendChild(oInput);
        oInput.select(); // 选择对象
        document.execCommand('Copy'); // 执行浏览器复制命令
        oInput.className = 'oInput';
        oInput.style.display = 'none';
        document.body.removeChild(oInput);
        message.success('微信公众号复制成功')
    };

    
    render() {
        return (
            <div className="mb-about-me">
                <div className="mb-am-d">
                    <div className="mb-am-dt">
                        <div className="mb-am-dt-n">联系我们</div>
                        <div className="mb-am-dt-com">
                            <div className="mb-am-dt-coms">公司名称</div>
                            <div id="order_sn" className="mb-am-dt-comt">深圳市景辰信息技术有限公司</div>
                        </div>
                        <div className="mb-am-dt-s">
                            <div className="mb-am-dt-ss">微信公众号：</div>
                            <div id="follow_wechat" className="mb-am-dt-st">lmgmm100</div>
                            <button className="mb-am-dt-sb" onClick={() => this.handleCopyWechat()}>复制</button>
                        </div>
                    </div>
                    <div className="mb-am-dq">
                        <div className="mb-am-dt-qr">
                            <div><img src={qrcode} className="mb-am-dt-qri" alt="lmgmm100"></img></div>
                        </div>
                        <div className="mb-am-dt-qrt">微信扫一扫,关注公众号</div>
                        <div className="mb-am-dt-add">
                            <div className="mb-am-dt-adds">公司地址</div>
                            <div id="order_sn" className="mb-am-dt-addt">深圳市龙华区大浪街道高峰社区鹊山新村39号3层3180室</div>
                        </div>
                    </div>
                </div>
            </div>
          );
    }
}

export default AboutMe;